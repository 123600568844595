
import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [

      // PAGES
        {
          path: '/',
          name: 'home',
          component: () => import('./views/pages/Home.vue'),
          meta: {
            auth: true
          }
        },

      // USER
        {
          path: '/user/setting',
          name: 'user-setting',
          component: () => import('@/views/user/setting/UserSetting.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User Setting', active: true }
            ],
            pageTitle: 'Setting',
            auth: true
          }
        },

        {
          path: '/users',
          name: 'users',
          component: () => import('@/views/user/UsersList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Users', active: true }
            ],
            pageTitle: 'Users',
            auth: true,
            role: '2',
            //level: ['1'],
          }
        },

      // CONFIG
        {
          path: '/config/menu',
          name: 'menu',
          component: () => import('@/views/config/menu/MenuList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Menu', active: true }
            ],
            pageTitle: 'Menu',
            auth: true,
            role: '2',
            //level: ['1'],
          }
        },

        {
          path: '/config/nav_menu',
          name: 'nav_menu',
          component: () => import('@/views/config/menu/NavMenuList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Navigation Menu', active: true }
            ],
            pageTitle: 'Navigation Menu',
            auth: true,
            role: '2',
            //level: ['1'],
          }
        },

        {
          path: '/config/roles',
          name: 'roles',
          component: () => import('@/views/config/role/RoleList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Roles', active: true }
            ],
            pageTitle: 'Roles',
            auth: true,
            role: '2',
            //level: ['1'],
          }
        },

        {
          path: '/config/role/menu/:slug',
          name: 'role-menu',
          component: () => import('@/views/config/role/RoleMenuList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Role Menu', active: true }
            ],
            pageTitle: 'Role Menu',
            auth: true,
            role: '2',
            //level: ['1'],
          }
        },

        {
          path: '/config/options',
          name: 'options',
          component: () => import('@/views/config/options/OptionsList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Options', active: true }
            ],
            pageTitle: 'Options',
            auth: true,
            role: '2',
            //level: ['1'],
          }
        },
      //

      // MENU
        {
          path: '/config/item_menu/:slug_nav_menu',
          name: 'item_menu',
          component: () => import('@/views/config/menu/ItemMenuList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Navigation Menu', url: '/config/nav_menu' },
              { title: 'Item Menu', active: true }
            ],
            pageTitle: 'Item Menu',
            parent: 'item_menu',
            auth: true,
            role: '2',
            //level: ['1'],
          }
        },
      //

      // CONTENT
        {
          path: '/content/post/:filter_type/:filter',
          name: 'post',
          component: () => import('@/views/post/Post.vue'),
          meta: {
            pageTitle: 'Post',
            auth: true,
            role: '2',
            //level: ['1'],
          }
        },
        {
          path: '/content/pages/:filter_type/:filter',
          name: 'pages',
          component: () => import('@/views/page/Pages.vue'),
          meta: {
            pageTitle: 'Pages',
            auth: true,
            role: '2',
            //level: ['1'],
          }
        },
        {
          path: '/content/event/:filter_type/:filter',
          name: 'event',
          component: () => import('@/views/event/Event.vue'),
          meta: {
            pageTitle: 'Event',
            auth: true,
            role: '2',
            //level: ['1'],
          }
        },
        {
          path: '/content/foto/:filter_type/:filter',
          name: 'foto',
          component: () => import('@/views/foto/Foto.vue'),
          meta: {
            pageTitle: 'Foto',
            auth: true,
            role: '2',
            //level: ['1'],
          }
        },
      //

      // Form
        {
          path: '/pengajuan/informasi-publik/:filter_type/:filter',
          name: 'informasi-publik',
          component: () => import('@/views/informasi-publik/Informasi.vue'),
          meta: {
            pageTitle: 'Informasi Publik',
            auth: true,
            //role: '2',
            //level: ['1'],
          }
        }


      ],
    },

    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/Login.vue'),
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            auth: true,
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        },
      ]
    },

    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if(store.getters['auth/guest']){
      next({ name: 'login' })
    }
    else{
      next()
    }
  } else {
    next()
  }

  if (to.matched.some(record => record.meta.role)) {
    if (store.getters['auth/otoritas'] === to.meta.role) {
      next()
    } else {
      next({ name: 'page-not-authorized' })
    }
  } else {
    next()
  }

/*
  if (to.matched.some(record => record.meta.level)) {
    //let level_user = ;
    if (to.meta.level.includes(store.getters['auth/level']) === true) {
      next()
    } else {
      next({ name: 'page-not-authorized' })
    }
  } else {
    next()
  }
*/

})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

export default router
